.home-content-one {
  width: 100%;
  background-size: cover;
  position: relative;
  &.enterAnimation {
    background: url("https://oss.5rs.me/oss/uploadfe/png/850943bd31527d569a27739f8d83724b.png") no-repeat center;
    background-size: cover;
    .home-content-one-container {
      .transition-right,
      .transition-left {
        opacity: 1;
        transform: translateX(0);
      }
      .transition-bottom {
        opacity: 1;
      }
    }
  }
  &-container {
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    // padding-top: 0.4rem;
    position: relative;
    &-carousel {
      width: 100vw;
      height: 100vh;
    }
    &-bannerImg {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
    .transition-right {
      opacity: 0;
      transform: translateX(0.8rem);
      transition: all 0.5s linear;
    }
    .down {
      transition: all 0.2s linear 0.2s;
    }
    .transition-left {
      transition: all 1s;
      opacity: 0;
      transform: translateX(-0.8rem);
    }
    .transition-bottom {
      transition: all 0.5s linear;
      opacity: 0;
    }
    .text {
      margin-top: 1.9rem;
      margin-left: 0.56rem;
      .mt10 {
        width: 4.55rem;
        height: 2.43rem;
        margin-bottom: 0.58rem;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .topics {
        width: 3.87rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .mt20 {
          margin-right: 0.28rem;
        }
        > div {
          display: flex;
          align-items: center;
          margin-bottom: 0.2rem;
          font-size: 0.18rem;
          color: #124089;
          line-height: 0.24rem;
          letter-spacing: 0.01rem;
          .point {
            width: 0.1rem;
            height: 0.1rem;
            background: #124089;
            border-radius: 50%;
            margin-right: 0.08rem;
          }
          > span {
            font-family: SourceHanSerifCN;
          }
        }
      }
    }
    .fr10 {
      position: absolute;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .phone {
      width: 7.19rem;
      height: 5.18rem;
      top: 1.2rem;
      right: -2.05rem;
      z-index: 11;
    }
    .pilot {
      width: 0.17rem;
      height: 0.21rem;
      left: 50%;
      transform: translateX(-0.085rem);
      bottom: 0.35rem;
      animation: doing 1.5s infinite linear;
    }
    @keyframes doing {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(0.1rem);
      }
      100% {
        transform: translateY(0.15rem);
      }
    }
    .circle-one {
      width: 10.21rem;
      height: 6.42rem;
      border-radius: 50%;
      top: 0;
      right: -4.02rem;
      z-index: 1;
    }
    .circle-bg {
      width: 12.89rem;
      height: 12.89rem;
      border-radius: 50%;
      top: -6.45rem;
      right: -6.7rem;
      z-index: 0;
      background: linear-gradient(to left, rgb(18, 64, 137), rgb(32, 130, 193));
    }
    .circle-two {
      width: 2.69rem;
      height: 2.69rem;
      position: absolute;
      left: -2.45rem;
      bottom: 0.37rem;
      z-index: 10;
    }
    .circle-three {
      width: 1.12rem;
      height: 1.12rem;
      position: absolute;
      transform: translate3d(6.1rem, -0.95rem, 0);
      z-index: 12;
    }
    .circle-four {
      width: 0.36rem;
      height: 0.36rem;
      transform: translate3d(5.49rem, -2.89rem, 0);
      background: #259ed6;
      z-index: 12;
    }
    .circle-four2 {
      width: 1.65rem;
      height: 1.65rem;
      border: 1px solid green;
      background: #259ed6;
      transform: translate3d(6.725rem, -4.945rem, 0);
      border-radius: 50%;
      z-index: 12;
    }
  }
}
.home-content-one-m {
  .home-content-one-container {
    height: 7.65rem;
    .pilot {
      display: none;
    }
    .item-left {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      left: -2.15rem;
      bottom: 0.37rem;
      z-index: 10;
      background: linear-gradient(to left, #2082c1, #124089);
      border-radius: 50%;
    }
    .item-center {
      position: absolute;
      z-index: 1;
      width: 0.36rem;
      height: 0.36rem;
      transform: translate3d(5.49rem, -3rem, 0);
      background: #259ed6;
      border-radius: 50%;
      z-index: 12;
    }
  }
}
