@import "@/styles/common";

.home-content-seven {
  width: 100%;
  height: 100vh;
  &.enterAnimation {
    background: url("https://oss.5rs.me/oss/uploadfe/png/81de393a162f25e80ce90cb66d7a7f19.png") no-repeat center;
    .home-content-seven-container {
      .left,
      .map {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  &-container {
    margin: 0 auto;
    width: 11.02rem;
    height: 7.65rem;
    position: relative;
    padding-top: 0.6rem;
    box-sizing: border-box;
    &.img-cover {
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .nav-menu {
      position: absolute;
      right: -0.1rem;
      bottom: 0.6rem;
      z-index: 10;
    }
    .circle {
      width: 0.54rem;
      height: 0.54rem;
      position: absolute;
      top: 0.5rem;
      left: -0.27rem;
    }
    .left {
      opacity: 0;
      transition: all 2s;
      transform: translateX(-1.5rem);
      box-sizing: border-box;
      padding-top: 0.39rem;
      float: left;
      margin: 0 0.49rem 0 0.08rem;
      .title {
        width: 2.47rem;
        height: 1.53rem;
        margin-bottom: 0.21rem;
      }
      .success {
        margin-bottom: 1.45rem;
        > div {
          width: 1.45rem;
          height: 0.23rem;
          background: #ffffff;
          border-radius: 0.12rem;
          text-align: center;
          line-height: 0.23rem;
          font-size: 0.12rem;
          letter-spacing: 0.01rem;
          font-family: SourceHanSansCN-Bold;
          font-weight: bold;
          color: #259ed6;
          margin-bottom: 0.09rem;
        }
        > p {
          font-size: 0.12rem;
          font-family: SourceHanSansCN-Medium;
          color: #ffffff;
          padding-left: 0.12rem;
          > span {
            font-size: 0.24rem;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #7bddff;
            line-height: 0.36rem;
            margin-right: 0.04rem;
          }
        }
      }
      .achievement {
        width: 2.52rem;
        height: 1.45rem;
      }
    }
    .map {
      opacity: 0;
      transition: all 2s;
      transform: translateX(1.5rem);
      float: left;
      box-sizing: border-box;
      width: 7.82rem;
      height: 6.46rem;
      position: relative;
      .nansha {
        width: 0.6rem;
        height: 0.79rem;
        position: absolute;
        left: 1.81rem;
        bottom: 0.48rem;
      }
    }
  }
}
.home-content-seven-m {
  height: 7.65rem;
  &.enterAnimation {
    background-size: cover;
  }
}
