@charset "UTF-8";
#___gatsby {
  padding-top: 0px;
}
// 1. Prevent mobile text size adjust after orientation change, without disabling user zoom.
// 2. Remove the gray background color from tap, default value is inherit
html {
  -ms-text-size-adjust: 100%; // 1
  -webkit-text-size-adjust: 100%; // 1
  -webkit-tap-highlight-color: transparent; // 2
  height: 100%;
}

// 1. Remove default margin
body {
  margin: 0; // 1
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Arial, sans-serif;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  height: 100%;
}

// HTML5 display definitions
//-----------------------------------------------------

// Correct `block` display not defined for any HTML5 element in IE 8/9.
// Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
// Correct `block` display not defined for `main` in IE 11.
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

// 1. Correct `inline-block` display not defined in IE 9-.
audio,
canvas,
progress,
video {
  display: inline-block; // 1
}

// Add the correct display in iOS 4-7.
audio:not([controls]) {
  display: none;
  height: 0;
}

// Add the correct vertical alignment in Chrome, Firefox, and Opera.
progress {
  vertical-align: baseline;
}

// Address `[hidden]` styling not present in IE 8/9/10.
// Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
[hidden],
template {
  display: none;
}

// Links
//-----------------------------------------------------

// 1. Remove the gray background color from active links in IE 10.
// 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
// 3. Improve readability when focused and also mouse hovered in all browsers.

a {
  background: transparent; // 1
  -webkit-text-decoration-skip: objects; /* 2 */
  text-decoration: none;
  color: #333;

  &:active {
    outline: 0; // 3
  }
}

// Text-level semantics
//-----------------------------------------------------

// 1. Remove the bottom border in Chrome 57- and Firefox 39-.
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

// Prevent the duplicate application of `bolder` by the next rule in Safari 6.
b,
strong {
  font-weight: inherit;
}

// Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
b,
strong {
  font-weight: bold;
}

// Address styling not present in Safari and Chrome.
dfn {
  font-style: italic;
}

// Address styling not present in IE 8/9.
mark {
  background: #ff0;
  color: #000;
}

// Address inconsistent and variable font size in all browsers.
small {
  font-size: 80%;
}

// Prevent `sub` and `sup` affecting `line-height` in all browsers.
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

// Embedded content
//-----------------------------------------------------

// 1. Remove border when inside `a` element in IE 8/9/10.
img {
  border: 0; // 1
  vertical-align: middle;
  max-width: 100%;
}

// Correct overflow not hidden in IE 9/10/11.
svg:not(:root) {
  overflow: hidden;
}

// Grouping content
//-----------------------------------------------------

// 1. Contain overflow in all browsers.
// 2. Improve readability of pre-formatted text in all browsers.
pre {
  overflow: auto; // 1
  white-space: pre; // 2
  white-space: pre-wrap; // 2
  word-wrap: break-word; // 2
}

// 1. Address odd `em`-unit font size rendering in all browsers.
code,
kbd,
pre,
samp {
  font-family: monospace, monospace; // 1
  font-size: 1em; // 2
}

// Forms
//-----------------------------------------------------

// Known limitation: by default, Chrome and Safari on OS X allow very limited
// styling of `select`, unless a `border` property is set.

// 1. Correct color not being inherited.
//    Known issue: affects color of disabled elements.
// 2. Correct font properties not being inherited.
// 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
button,
input,
optgroup,
select,
textarea {
  color: inherit; // 1
  font: inherit; // 2
  margin: 0; // 3
  vertical-align: middle;
}

// Show the overflow in IE.
// 1. Show the overflow in Edge.
// 2. Show the overflow in Edge, Firefox, and IE.
button,
input, // 1
select { // 2
  overflow: visible;
}

// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
// Correct `select` style inheritance in Firefox.
button,
select {
  text-transform: none;
}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
//    and `video` controls.
// 2. Correct inability to style clickable `input` types in iOS.
// 3. Improve usability and consistency of cursor style between image-type
//    `input` and others.
button,
html input[type="button"], // 1
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; // 2
  cursor: pointer; // 3
}

// Re-set default cursor for disabled elements.
[disabled] {
  cursor: default;
}

// Remove inner padding and border in Firefox 4+.
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// 1. Address Firefox 4+ setting `line-height` on `input` using `!important` in
//    the UA stylesheet.
input {
  line-height: normal; // 1
}

// It's recommended that you don't attempt to style these elements.
// Firefox's implementation doesn't respect box-sizing, padding, or width.

// 1. Address box sizing set to `content-box` in IE 8/9/10.
// 2. Remove excess padding in IE 8/9/10.
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; // 1
  padding: 0; // 2
}

// Fix the cursor style for Chrome's increment/decrement buttons. For certain
// `font-size` values of the `input`, it causes the cursor style of the
// decrement button to change from `default` to `text`.
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

// 1. Address `appearance` set to `searchfield` in Safari and Chrome.
// 2. Address `box-sizing` set to `border-box` in Safari and Chrome
//    (include `-moz` to future-proof).
input[type="search"] {
  -webkit-appearance: textfield; // 1
  box-sizing: border-box;
}

// Remove inner padding and search cancel button in Safari and Chrome on OS X.
// Safari (but not Chrome) clips the cancel button when the search input has
// padding (and `textfield` appearance).
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

// Define consistent border, margin, and padding.
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

// 1. Correct the text wrapping in Edge and IE.
// 2. Correct the color inheritance from `fieldset` elements in IE.
// 3. Remove the padding so developers are not caught out when they zero out
//    `fieldset` elements in all browsers.
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

// 1. Remove default vertical scrollbar in IE 8/9/10/11.
textarea {
  overflow: auto; // 1
  resize: vertical;
  vertical-align: top;
}

// Don't inherit the `font-weight` (applied by a rule above).
// NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
optgroup {
  font-weight: bold;
}

// webkit focus outline
input,
select,
textarea,
button {
  outline: 0;
}

// Android 下 input focus 消除高亮外框
textarea,
input {
  -webkit-user-modify: read-write-plaintext-only;
}

// ie10 clear & password
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

// 表单placeholder样式
// 注意不可联合申明，否则无效
// Firefox 19+
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999;
}

// Internet Explorer 10+
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999;
}

// Safari and Chrome
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999;
}

// Tables
//-----------------------------------------------------
// Remove most spacing between table cells.
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

// 根据使用习惯，对normalize上进行补充
//-----------------------------------------------------

h1, h2, h3, h4, h5, h6, p, figure, form, blockquote {
  margin: 0;
}

// ul ol dl
ul, ol, li, dl, dd {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none outside none;
}

// icon
i {
  font-style: normal;
}

// 所有元素为border-box
* {
  box-sizing: border-box;
}

a[title="站长统计"] {
  display: none;
}

#logo {
  position: absolute;
  top: 0;
  display: none;
  width: 171px;
  height: 114px;
  a {
    display: block;
    width: 171px;
    height: 114px;
    background-image: url('https://oss.5rs.me/oss/uploadfe/jpg/7f7aae582f964925c9a95583827c2678.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    text-indent: -9999px;
  }
}
