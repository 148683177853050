@import "~@/assets/sass/_common.scss";
@import "~@/assets/sass/_base";

body {
  // 定义全局css 变量
  --minWidth: 1180px;
  --borderBase: 4px;
}

#App {
  height: 100vh;
}
