@import "@/styles/common";

.home-content-six {
  width: 100%;
  height: 100vh;
  &.enterAnimation {
    background: url("https://oss.5rs.me/oss/uploadfe/png/2da9531ccd64a2263de36d3d88570c8a.png") center center/19.20rem
      100% no-repeat;
    // background: #F3F4F4;
    .home-content-six-container {
      .team,
      .people-message,
      .people-pic {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  &-container {
    margin: 0 auto;
    width: 10.8rem;
    height: 7.65rem;
    position: relative;
    box-sizing: border-box;
    position: relative;
    .nav-menu {
      position: absolute;
      right: -0.45rem;
      bottom: 0.56rem;
    }
    .team {
      opacity: 0;
      transition: all 1s;
      transform: translateX(-1.5rem);
      position: absolute;
      top: 0.97rem;
      width: 2.82rem;
      height: 0.64rem;
      object-fit: cover;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .people {
      display: flex;
      height: 7.65rem;
      &-message {
        opacity: 0;
        transition: all 1s;
        transform: translateX(-1.5rem);
        width: 2.02rem;
        margin: 0 0.28rem 0 0.27rem;
        padding-top: 2.73rem;
        > div {
          // height: .31rem;
          max-height: 0.6rem;
          font-size: 0.24rem;
          white-space: pre-wrap;
          color: #259ed6;
          line-height: 0.31rem;
          margin-bottom: 0.25rem;
          font-family: SourceHanSerifCN;
        }
        .mr-top {
          margin-top: 1rem;
          margin-left: 0.5rem;
        }
        > p {
          font-size: 0.12rem;
          color: #124089;
          white-space: pre-wrap;
          line-height: 0.2rem;
          font-family: SourceHanSerifCN;
        }
      }
      &-pic {
        opacity: 0;
        transition: all 1s;
        transform: translateX(1.5rem);
        display: flex;
        padding-top: 2.56rem;
        &-item {
          .item {
            width: 0.8rem;
            height: 3.5rem;
            margin-right: 0.09rem;
            position: relative;
            margin-bottom: 0.01rem;
            transition: all 0.3s linear;
            // cursor: pointer;
            > img {
              width: 100%;
              border-radius: 0.08rem;
              display: block;
              margin: 0 auto;
              height: 3.5rem;
              object-fit: cover;
            }
            > span {
              display: block;
              width: 0.82rem;
              height: 0.45rem;
              background: linear-gradient(90deg, #2082c1 0%, rgba(18, 64, 137, 0) 100%);
              border-radius: 0.08rem;
              position: absolute;
              bottom: 0.32rem;
              left: -0.05rem;
              font-size: 0.16rem;
              color: #ffffff;
              padding-left: 0.15rem;
              box-sizing: border-box;
              line-height: 0.45rem;
              font-family: SourceHanSerifCN;
            }
            &.active {
              width: 2.23rem;
              > span {
                display: block;
                width: 1.67rem !important;
                height: 0.68rem !important;
                background: linear-gradient(90deg, #2082c1 0%, rgba(18, 64, 137, 0) 100%);
                border-radius: 0.08rem;
                position: absolute;
                bottom: 0.23rem;
                left: -0.06rem;
                font-size: 0.24rem !important;
                color: #ffffff;
                padding-left: 0.19rem;
                box-sizing: border-box;
                line-height: 0.68rem !important;
              }
            }
          }

          .inverted {
            width: 0.79rem;
            height: 0.85rem;
            background: linear-gradient(360deg, rgba(1, 16, 74, 0) 0%, rgba(0, 37, 113, 0.39) 100%);
            border-radius: 0.18rem 0.18rem 0rem 0rem;
            opacity: 0.5;
            transition: all 0.3s linear;
            &.checked {
              width: 2.21rem !important;
            }
          }
        }
      }
    }
  }
}
.home-content-six-m {
  height: 7.65rem;
}
