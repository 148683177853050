@import "@/styles/common";

.home-content-fif {
  width: 100%;
  height: 100vh;
  background-size: cover;
  &.enterAnimation {
    background: linear-gradient(270deg, #015091 0%, #2183bc 100%);
    .home-content-fif-container {
      .left,
      .right,
      .animate {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  &-container {
    margin: 0 auto;
    width: 10.8rem;
    height: 7.65rem;
    position: relative;
    display: flex;
    overflow: visible;
    .nav-menu {
      position: absolute;
      right: -0.1rem;
      bottom: 0.66rem;
      height: auto;
      z-index: 999;
    }
    .page-tab {
      overflow: visible !important;
    }
    .left {
      opacity: 0;
      transition: all 2s;
      transform: translateX(-1.5rem);
      width: 2.67rem;
      padding-top: 0.91rem;
      margin-right: 2.7rem;
      &-title {
        width: 2.29rem;
        height: 1.32rem;
        margin-bottom: 0.61rem;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .years-wrapper {
        height: 5.1rem;
        position: relative;
        overflow: hidden;
        .btn-item {
          position: absolute;
          top: 1.2rem;
          left: 0.1rem;
          width: 1.54rem;
          height: 0.44rem;
          background: url("https://oss.5rs.me/oss/uploadfe/png/f4522decd926078c3e6744e30c1730c7.png") no-repeat center;
          > img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0.17rem;
          }
        }
        .years {
          position: relative;
          transition: transform 1s;
          margin-left: 0.1rem;
          .click-tip {
            position: absolute;
            top: 0;
            left: 1.54rem;
            padding-top: 0.05rem;
            .text {
              width: 0.53rem;
              font-size: 0.12rem;
              color: #ffffff;
              line-height: 0.16rem;
              letter-spacing: 0.01rem;
            }
          }
          &-item:hover {
            background: url("https://oss.5rs.me/oss/uploadfe/png/f4522decd926078c3e6744e30c1730c7.png") no-repeat center;
            font-size: 0.24rem;
            opacity: 1 !important;
          }
          &-item {
            text-align: center;
            width: 1.54rem;
            height: 0.44rem;
            line-height: 0.44rem;
            font-size: 0.22rem;
            color: #ffffff;
            position: relative;
            font-family: SourceHanSerifCN;
            transition: font-size 0.3s linear;
            cursor: pointer;
            .year {
              // margin-right: 0.24rem;
              font-family: SourceHanSerifCN;
            }
            > img {
              position: absolute;
              top: 0.13rem;
              right: 0.17rem;
            }
          }
          .active {
            font-family: SourceHanSerifCN;
            // background: url("https://oss.5rs.me/oss/uploadfe/png/f4522decd926078c3e6744e30c1730c7.png")
            //     no-repeat center;
            font-size: 0.24rem;
            opacity: 1 !important;
          }
          .item0 {
            opacity: 0.8;
          }
          .item1 {
            opacity: 0.7;
          }
          .item2 {
            opacity: 0.6;
          }
          .item3 {
            opacity: 0.5;
          }
          .item4 {
            opacity: 0.4;
          }
          .item5 {
            opacity: 0.3;
          }
          .item6 {
            opacity: 0.2;
          }
          .item7 {
            opacity: 0.1;
          }
          .item8 {
            opacity: 0.05;
          }
        }
      }
    }

    .animate {
      opacity: 0;
      transition: all 2s;
      transform: translateX(1.5rem);
      // width: 4.89rem;
      // height: 7.46rem;
      position: absolute;
      left: 5.06rem;
      bottom: 0;
      overflow: hidden;
      &.clipPath {
        clip-path: circle(0);
      }
    }
    .right {
      opacity: 0;
      transition: all 2s;
      transform: translateX(1.5rem);
      width: 4.89rem;
      font-size: 0.12rem;
      color: #124089;
      position: relative;
      overflow: visible;
      .courseleft {
        opacity: 0;
        animation-fill-mode: forwards !important;
        display: flex;
        border-radius: 0.34rem 0.05rem 0.05rem 0.34rem;
        background: #e5f5ff;
        position: absolute;
        .month {
          width: 0.53rem;
          height: 0.53rem;
          line-height: 0.53rem;
          text-align: center;
          background: #259ed6;
          border-radius: 50%;
          color: #fff;
          > span {
            font-size: 0.24rem;
          }
        }
        .content {
          width: 1.11rem;
          min-height: 0.53rem;
          // padding: 0.05rem;
          position: relative;
          word-break: break-all;
          display: flex;
          align-items: center;
          > p {
            position: absolute;
            width: 115%;
            top: 42%;
            left: -7%;
            transform: scale(0.83) translateY(-50%);
            font-size: 0.12rem;
            font-family: SourceHanSerifCN;
          }
        }
        .long-text {
          width: 1.61rem;
        }
        .long-long-text {
          width: 1.91rem;
        }
        .super-long-text {
          width: 2.41rem;
        }
      }
      .courseright {
        opacity: 0;
        animation-direction: alternate !important;
        animation-fill-mode: forwards !important;
        display: flex;
        border-radius: 0.04rem 0.34rem 0.34rem 0.04rem;
        background: #e5f5ff;
        position: absolute;
        .content {
          width: 1.11rem;
          position: relative;
          min-height: 0.53rem;
          // padding: 0.05rem;
          word-break: break-all;
          display: flex;
          align-items: center;
          > p {
            position: absolute;
            width: 115%;
            top: 42%;
            left: -5%;
            transform: scale(0.83) translateY(-50%);
            font-size: 0.12rem;
            font-family: SourceHanSerifCN;
          }
        }
        .long-text {
          width: 1.61rem;
        }
        .long-long-text {
          width: 1.91rem;
        }
        .super-long-text {
          width: 2.41rem;
        }
        .month {
          width: 0.53rem;
          height: 0.53rem;
          line-height: 0.53rem;
          text-align: center;
          background: #259ed6;
          border-radius: 50%;
          color: #fff;
          > span {
            font-size: 0.24rem;
          }
        }
      }
      .course1 {
        left: 0.15rem;
        bottom: 0.82rem;
        transform-origin: right top;
      }
      .course2 {
        left: 2.51rem;
        bottom: 1.42rem;
        transform-origin: left bottom;
      }
      .course3 {
        left: -1.55rem;
        bottom: 2.3rem;
        transform-origin: right top;
      }
      .course4 {
        left: 2.51rem;
        bottom: 2.32rem;
        transform-origin: left top;
      }
      .course5 {
        left: 0.15rem;
        bottom: 3.9rem;
        transform-origin: right bottom;
      }
      .course6 {
        left: 2.51rem;
        bottom: 4.52rem;
        transform-origin: left bottom;
      }
      .course7 {
        left: -1.7rem;
        bottom: 4.6rem;
        transform-origin: right top;
      }
      .course8 {
        left: 2.51rem;
        bottom: 5.51rem;
        transform-origin: left bottom;
      }
      .course9 {
        left: -2.1rem;
        bottom: 5.39rem;
        transform-origin: right bottom;
      }
      .course10 {
        left: 2.51rem;
        bottom: 5.3rem;
        transform-origin: left top;
      }
      .longText-left {
        left: -2.2rem;
      }
      .longlongText-left {
        left: -0.65rem;
      }
      .superlongText-left {
        left: -1.15rem;
      }
      .longleaf4 {
        left: -2.05rem;
      }

      .doing1 {
        animation: transformOne 1s linear;
      }
      .doing2 {
        animation: transformOne 1s linear;
      }
      .doing3 {
        animation: transformOne 1s linear;
      }
      .doing4 {
        animation: transformTwo 1s linear;
      }
      .doing5 {
        animation: transformTwo 1s linear;
      }
      .doing6 {
        animation: transformOne 1s linear;
      }
      .doing7 {
        animation: transformThree 1s linear;
      }
      .doing8 {
        animation: transformFour 1s linear;
      }
      .doing9 {
        animation: transformTwo 1s linear;
      }
      .doing10 {
        animation: transformFour 1s linear;
      }
      .leave {
        animation: transformReverse 0.5s linear;
      }
      @keyframes transformOne {
        0% {
          opacity: 0;
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(5deg);
        }
        50% {
          transform: rotate(10deg);
        }
        75% {
          transform: rotate(5deg);
        }
        100% {
          opacity: 1;
          transform: rotate(0deg);
        }
      }
      @keyframes transformOneReverse {
        0% {
          opacity: 1;
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(5deg);
        }
        50% {
          transform: rotate(10deg);
        }
        75% {
          transform: rotate(5deg);
        }
        100% {
          opacity: 0;
          transform: rotate(0deg);
        }
      }
      @keyframes transformTwo {
        0% {
          opacity: 0;
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(-2deg);
        }
        50% {
          transform: rotate(-4deg);
        }
        75% {
          transform: rotate(-2deg);
        }
        100% {
          opacity: 1;
          transform: rotate(0deg);
        }
      }
      @keyframes transformTwoReverse {
        0% {
          opacity: 1;
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(-2deg);
        }
        50% {
          transform: rotate(-4deg);
        }
        75% {
          transform: rotate(-2deg);
        }
        100% {
          opacity: 0;
          transform: rotate(0deg);
        }
      }
      @keyframes transformThree {
        0% {
          opacity: 0;
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(2deg);
        }
        50% {
          transform: rotate(4deg);
        }
        75% {
          transform: rotate(2deg);
        }
        100% {
          opacity: 1;
          transform: rotate(0deg);
        }
      }
      @keyframes transformThreeReverse {
        0% {
          opacity: 1;
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(2deg);
        }
        50% {
          transform: rotate(4deg);
        }
        75% {
          transform: rotate(2deg);
        }
        100% {
          opacity: 0;
          transform: rotate(0deg);
        }
      }
      @keyframes transformFour {
        0% {
          opacity: 0;
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(-5deg);
        }
        50% {
          transform: rotate(-10deg);
        }
        75% {
          transform: rotate(-5deg);
        }
        100% {
          opacity: 1;
          transform: rotate(0deg);
        }
      }
      @keyframes transformReverse {
        0% {
          opacity: 1;
          transform: translateY(0rem);
        }
        100% {
          opacity: 0;
          transform: translateY(0.3rem);
        }
      }
    }
  }
}
.home-content-fif.home-content-fif-m {
  height: unset;
}
