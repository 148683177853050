@import "@/styles/common";

.home-content-four {
  width: 100%;
  height: 100vh;
  &.enterEnd {
    background: url("https://oss.5rs.me/oss/uploadfe/png/9d1500f53074a97579e23de1dbccf693.png") top right/19.2rem 100%
      no-repeat;
    .home-content-four-container {
      .bg-area {
        background: url("https://oss.5rs.me/oss/uploadfe/png/264752210b7cef0e3bbf55f7bc8c1f85.png") no-repeat left top;
        background-size: cover;
      }
    }
  }
  &.leaveStart {
    background: none;
    .home-content-four-container {
      .bg-area {
        background: none;
      }
    }
  }
  &-container {
    margin: 0 auto;
    width: 10.8rem;
    height: 7.65rem;
    position: relative;
    .nav-menu {
      position: absolute;
      right: 0;
      bottom: 0.66rem;
    }
    // 左边文本区
    .bg-area {
      opacity: 1;
      width: 10.21rem;
      height: 6.36rem;
      margin-left: -4.2rem;
      .area {
        position: absolute;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .point {
        width: 0.54rem;
        height: 0.54rem;
        top: 0.6rem;
        left: -0.01rem;
      }
      .title {
        width: 0;
        height: 0;
        top: 1.38rem;
        left: 0.38rem;
        opacity: 0;
        transition: all 1s linear;
      }
      .title-none-to-show {
        width: 2.76rem;
        height: 0.68rem;
        top: 1.04rem;
        left: 0.38rem;
        opacity: 1;
      }
      .text {
        width: 3.08rem;
        height: 2.48rem;
        padding: 2.81rem 0 0 0.38rem;
        box-sizing: border-box;
        overflow: hidden;
        .culture {
          // width: 3.08rem;
          // height: 2.48rem;
          position: absolute;
          transition: all 1s linear;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .culture0 {
          width: 0;
          height: 0;
          top: 3.82rem;
          left: 0.38rem;
          opacity: 0;
        }
        .text-none-to-show0 {
          width: 3.08rem;
          height: 2.48rem;
          top: 2.58rem;
          left: 0.38rem;
          opacity: 1;
        }
        .culture1 {
          width: 0;
          height: 0;
          top: 3.83rem;
          left: -2.7rem;
        }
        .culture2 {
          width: 0;
          height: 0;
          top: 3.83rem;
          left: -5.78rem;
        }
        .culture3 {
          width: 0;
          height: 0;
        }
        .culture4 {
          width: 0;
          height: 0;
        }
      }
      .text-three-to-none {
        animation: text-three-to-none 1s linear forwards;
      }
      .text-two-to-three {
        animation: text-two-to-three 1s linear forwards;
      }
      .text-one-to-two {
        animation: text-one-to-two 1s linear forwards;
      }
      //第三个text消失
      @keyframes text-three-to-none {
        0% {
          width: 3.08rem;
          height: 2.48rem;
          transform: translate(0, 0) scale(1);
          opacity: 1;
        }

        100% {
          width: 0;
          height: 0;
          transform: translate(3.46rem, 1.25rem);
          opacity: 0;
        }
      }
      //第二个text变成第三个text
      @keyframes text-two-to-three {
        0% {
          width: 0;
          height: 0;
          transform: translate(3.08rem, 0);
          opacity: 0;
        }

        100% {
          width: 3.08rem;
          height: 2.48rem;
          transform: translate(3.08rem, -1.25rem) scale(1);
          opacity: 1;
        }
      }
      //第一个text变成第二个text
      @keyframes text-one-to-two {
        0% {
          width: 0;
          height: 0;
          transform: translate(0, 0) scale(0);
          opacity: 0;
        }

        100% {
          width: 0;
          height: 0;
          transform: translate(3.08rem, 0);
        }
      }
    }
    // 右边球区
    > .item {
      position: absolute;
      border-radius: 50%;
      transition: all 1s linear;
      cursor: pointer;
      > img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
        box-shadow: 0 0 0.15rem 0 rgb(0, 0, 0, 0.15);
      }
    }
    .item0 {
      width: 0;
      height: 0;
      top: 3.83rem;
      left: 7.43rem;
      opacity: 0;
      z-index: 4;
    }
    .item1 {
      width: 0;
      height: 0;
      top: 3.88rem;
      left: 8.89rem;
      opacity: 0;
      z-index: 3;
    }
    .item2 {
      width: 0;
      height: 0;
      top: 3.51rem;
      left: 9.58rem;
      opacity: 0;
      z-index: 2;
    }
    .item3 {
      width: 0;
      height: 0;
      top: 3.51rem;
      left: 9.09rem;
      z-index: 1;
    }
    .item4 {
      width: 0;
      height: 0;
      z-index: 0;
    }

    // 初入页面-动画
    .circle-none-to-show0 {
      width: 3.6rem;
      height: 3.6rem;
      top: 2.03rem;
      left: 3.84rem;
      opacity: 1;
    }
    .circle-none-to-show1 {
      width: 1.85rem;
      height: 1.85rem;
      top: 2.95rem;
      left: 7.03rem;
      opacity: 1;
    }
    .circle-none-to-show2 {
      width: 0.97rem;
      height: 0.97rem;
      top: 3.2rem;
      left: 8.61rem;
      opacity: 1;
    }

    // 点击第二个圆
    .one-to-none {
      animation: one-to-none 1s linear forwards;
    }
    .two-to-one {
      animation: two-to-left1 1s linear forwards;
    }
    .three-to-two {
      animation: three-to-left2 1s linear forwards;
    }
    .four-to-show {
      animation: four-to-show 1s linear forwards;
    }

    // 点击第三个圆
    .one-to-none-clickthree {
      animation: one-to-none 1s linear forwards;
    }
    .two-to-one-clickthree {
      animation: two-to-three 1s linear forwards;
    }
    .three-to-one {
      animation: three-to-one 1s linear forwards;
    }

    // 点击第二个圆
    // 第一个消失
    @keyframes one-to-none {
      0% {
        width: 3.6rem;
        height: 3.6rem;
        top: 2.03rem;
        left: 3.84rem;
        transform: translate(0, 0);
        opacity: 1;
      }

      100% {
        width: 0rem;
        height: 0rem;
        top: 2.03rem;
        left: 3.84rem;
        opacity: 0;
        transform: translate(0, 1.8rem);
      }
    }
    // 第二个移动到第一个
    @keyframes two-to-left1 {
      0% {
        left: 7.03rem;
        top: 2.95rem;
        width: 1.85rem;
        height: 1.85rem;
        transform: translate(0, 0);
      }

      100% {
        width: 3.6rem;
        height: 3.6rem;
        left: 7.03rem;
        top: 2.95rem;
        transform: translate(-3.19rem, -0.92rem);
      }
    }

    /* 第三个移动到第二个 */
    @keyframes three-to-left2 {
      0% {
        width: 0.97rem;
        height: 0.97rem;
        top: 3.2rem;
        left: 8.61rem;
        transform: translate(0, 0);
      }

      100% {
        width: 1.85rem;
        height: 1.85rem;
        top: 3.2rem;
        left: 8.61rem;
        transform: translate(-1.58rem, -0.25rem);
      }
    }
    // 第四个出现
    @keyframes four-to-show {
      0% {
        width: 0;
        height: 0;
        top: 3.51rem;
        left: 9.09rem;
        transform: translate(0, 0);
      }

      100% {
        width: 0.97rem;
        height: 0.97rem;
        top: 3.51rem;
        left: 9.09rem;
        opacity: 1;
        transform: translate(-0.48rem, -0.31rem);
      }
    }

    // 点击第三个圆
    // 第三个移动到第一个
    @keyframes three-to-one {
      0% {
        width: 0.97rem;
        height: 0.97rem;
        top: 3.2rem;
        left: 8.61rem;
        transform: translate(0, 0);
      }

      100% {
        top: 3.2rem;
        left: 8.61rem;
        width: 3.6rem;
        height: 3.6rem;
        transform: translate(-4.77rem, -1.17rem);
      }
    }

    @keyframes two-to-three {
      0% {
        left: 7.03rem;
        top: 2.95rem;
        width: 1.85rem;
        height: 1.85rem;
        opacity: 1;
        transform: translate(0, 0);
      }

      50% {
        width: 3.6rem;
        height: 3.6rem;
        left: 7.03rem;
        top: 2.95rem;
        opacity: 1;
        transform: translate(-3.19rem, -0.92rem);
      }

      100% {
        width: 0rem;
        height: 0rem;
        left: 7.03rem;
        top: 2.95rem;
        opacity: 0;
        transform: translate(-3.19rem, 0.88rem);
      }
    }
  }
}
.home-content-four-m {
  height: 7.65rem;
}
