.Home-asdhja1231 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  overflow: hidden;
  background: #f8f8f8;
  .animation-circle {
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(270deg, #2082c1 0%, #124089 100%);
    &1 {
      bottom: 0;
      right: 0;
      width: 1000px;
      height: 1000px;
      transform: translate(557px, 657px) scale(0);
    }
    &2 {
      width: 467px;
      height: 467px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 31px;
      opacity: 0;
    }
    &3 {
      width: 1152px;
      height: 1152px;
      top: unset;
      bottom: 0;
      left: 50%;
      transform: translate(-355px, 666px);
      margin-top: 31px;
      opacity: 0;
    }
    &4 {
      width: 1152px;
      height: 1152px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0;
      opacity: 0;
    }
    &5 {
      width: 1800px;
      height: 1800px;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      margin-top: -60px;
      opacity: 0;
    }
    &6 {
      width: 1800px;
      height: 1800px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0px;
      opacity: 0;
    }
    &7 {
      width: 1800px;
      height: 1800px;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      margin-top: -60px;
      opacity: 0;
    }
  }
  // .circle-panel-4-3 {
  //   position: absolute;
  //   top: 275px;
  //   left: -75px;
  //   width: 124px;
  //   height: 124px;
  //   border-radius: 50%;
  //   background: linear-gradient(300deg, #e9f2f6 0%, #e8f1f6 100%);
  //   opacity: 1;
  // }
  .circle {
    position: absolute;
    top: 0;
    left: -40px;
    opacity: 1;
    max-width: unset;
    width: 250px;
    height: 250px;
  }
  .scroll-box {
    position: relative;
    z-index: 10;
    flex: 1;
    margin-top: 62px;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    .panel {
      scroll-snap-align: start;
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 24px;
      box-sizing: border-box;
      overflow: hidden;
      &-1 {
        padding: 0;
        width: 100vw;
        height: 100vh;
        background-color: #f8f8f8;
        .text-box {
          transform: scaleX(0);
          transform-origin: left center;
          div.title {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            padding-left: 10px;
            span {
              &:first-child {
                font-weight: 600;
                font-size: 40px;
                line-height: 70px;
                letter-spacing: 5.4px;
                color: #124089;
              }
              &:nth-child(2) {
                font-size: 40px;
                color: #259ed6;
                line-height: 52px;
                letter-spacing: 5.4px;
              }
            }
          }
          ul {
            padding-left: 10px;
            color: #124089;
            font-size: 16px;
            li {
              display: flex;
              align-items: center;
              &:not(:last-child) {
                margin-bottom: 8px;
              }
              &::before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 8px;
                line-height: 22px;
                font-size: 16px;
                border-radius: 50%;
                background: #124089;
              }
            }
          }
        }
        .content-img {
          position: absolute;
          width: 113.33vw;
          max-width: unset;
          bottom: 20px;
          right: -35.33vw;
          z-index: 0;
        }
        .circle-num {
          position: absolute;
          left: 70px;
          bottom: 40px;
          display: block;
          width: 102px;
          height: 102px;
          padding: 0 18px;
          color: #fff;
          border-radius: 50%;
          text-align: center;
          background: linear-gradient(270deg, #2082c1 0%, #124089 100%);
          i {
            font-size: 48px;
            color: #fff;
          }
        }
        .pull-up {
          position: absolute;
          z-index: 20;
          bottom: 80px;
          left: 50%;
          transform: translate(-50%);
          width: 20px;
          height: 30px;
        }
        &-contentImg {
          width: 100vw;
          height: 100vh;
          object-fit: cover;
        }
      }
      &-2 {
        .content-box-2 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 467px;
          height: 467px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .img1 {
            width: 128px;
            height: 102px;
            margin-top: 90px;
            margin-bottom: 27px;
          }
          .img2 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: unset;
            width: 603px;
            height: 603px;
          }
          .left-title {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin-top: -228px;
            margin-left: -81px;
            width: 134px;
            height: 134px;
            border-radius: 50%;
            background: #259ed6;
            .img3 {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 120px;
              height: 120px;
            }
            .img4 {
              width: 56px;
              height: 30px;
            }
          }
          .content-text {
            text-align: center;
            .content-title {
              font-size: 24px;
              margin-bottom: 30px;
              color: #fff;
            }
          }
          p {
            width: 337px;
            line-height: 28px;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
        }
      }
      &-3 {
        .circle-panel-3-1 {
          position: absolute;
          z-index: -1;
          top: 311px;
          left: -83px;
          width: 124px;
          height: 124px;
          border-radius: 50%;
          background: linear-gradient(300deg, #e8f1f6 0%, #e8f1f6 100%);
        }
        h3 {
          // margin-top: 20px;
          margin-left: 27px;
          font-size: 40px;
          font-weight: 600;
          line-height: 86px;
          color: #7bddff;
        }
        .prod-box {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 256px;
          margin: auto;
          opacity: 0;
          margin-top: 30px;
          img {
            width: 116px;
            height: 116px;
            margin-bottom: 30px;
          }
        }
      }
      &-4 {
        .circle-panel-4-1 {
          position: absolute;
          z-index: -1;
          top: 8px;
          left: -22px;
          width: 126px;
          height: 126px;
          border-radius: 50%;
          background: linear-gradient(300deg, #fff 0%, #7fdeff 100%);
        }
        .circle-panel-4-2 {
          position: absolute;
          z-index: -1;
          top: 139px;
          left: -10px;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background: linear-gradient(300deg, #dfeef5 0%, #d1e8f2 100%);
        }
        h3 {
          margin-left: 27px;
          margin-bottom: 40px;
          font-size: 40px;
          font-weight: 600;
          line-height: 86px;
          color: #124089;
        }
        .circle-box {
          position: relative;
          height: 120px;
          width: 120px;
          margin: auto;
          margin-bottom: 55px;
          .content-img {
            img {
              transition: all 0.2s;
              width: 120px;
              height: 120px;
            }
          }
          .carousel-content {
            overflow: unset;
            .adm-swiper-slide-active {
              z-index: 10;
              img {
                transform: scale(1.52);
              }
            }
            .adm-swiper-indicator {
              display: none;
            }
          }
        }
        .des {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 0 85px;
          h4 {
            font-size: 24px;
            line-height: 43px;
            color: #ffffff;
          }
          i {
            width: 114px;
            height: 4px;
            margin: 9px 0 16px;
            border-radius: 10px;
            opacity: 1;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 1%,
              rgba(255, 255, 255, 0.68) 50%,
              rgba(255, 255, 255, 0) 99%
            );
          }
          .sub-title {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
          }
          .content {
            font-size: 14px;
            line-height: 25px;
            color: #ffffff;
          }
        }
      }
      &-5 {
        & > h3 {
          margin-left: 27px;
          font-size: 40px;
          font-weight: normal;
          line-height: 60px;
          display: flex;
          align-items: center;
          color: #7bddff;
        }
        & > h4 {
          margin-left: 27px;
          font-size: 20px;
          font-weight: normal;
          line-height: 30px;
          display: flex;
          align-items: center;
          color: #7bddff;
        }
        .tree {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: -1;
          opacity: 0;
          // position: relative;
          // width: 375px;
          // height: 558px;
          // margin: auto;
          // #animate {
          //   position: relative;
          //   left: 50%;
          //   top: 50%;
          //   margin-left: -15px;
          //   transform: translate(-50%, -50%);
          // }
          &-bg {
            position: relative;
            transform: scale(0.9) translate(-17px);
          }
        }
        .tabs {
          position: absolute;
          width: 100%;
          z-index: 20;
          bottom: 0;
          .adm-tabs {
            .adm-tabs-header {
              border-bottom: none;
            }
            .adm-tabs-header-mask {
              display: none;
              background: linear-gradient(to right, rgba(27, 104, 171), rgba(27, 104, 171, 0));
            }
            .adm-tabs-tab-wrapper {
              padding: 0 30px;
            }
            .adm-tabs-tab {
              font-size: 16px;
              font-weight: normal;
              line-height: 29px;
              color: #9cbed7;
              &.adm-tabs-tab-active {
                position: relative;
                color: #fff;
                &::before {
                  content: "";
                  position: absolute;
                  z-index: -1;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 162px;
                  height: 47px;
                  background: url(https://oss.5rs.me/oss/uploadfe/png/456c3d96987c6f8a1d66682207ccce28.png) no-repeat
                    center center/100% 100%;
                }
              }
            }
            .adm-tabs-tab-line {
              display: none;
            }
          }
        }
        .right {
          opacity: 1;
          transition: all 2s;
          // transform: translateX(1.5rem);
          width: 100%;
          height: 100%;
          font-size: 12px;
          color: #124089;
          position: absolute;
          z-index: 2;
          overflow: visible;
          .courseleft {
            opacity: 0;
            animation-fill-mode: forwards !important;
            display: flex;
            border-radius: 0.34rem 0.05rem 0.05rem 0.34rem;
            background: #e5f5ff;
            position: absolute;
            .month {
              width: 0.53rem;
              height: 0.53rem;
              line-height: 0.53rem;
              text-align: center;
              background: #259ed6;
              border-radius: 50%;
              color: #fff;
              > span {
                font-size: 0.24rem;
              }
            }
            .content {
              width: 1.11rem;
              min-height: 0.53rem;
              // padding: 0.05rem;
              position: relative;
              word-break: break-all;
              display: flex;
              align-items: center;
              > p {
                position: absolute;
                width: 115%;
                top: 42%;
                left: -7%;
                transform: scale(0.83) translateY(-50%);
                font-size: 0.12rem;
                font-family: SourceHanSerifCN;
              }
            }
            .long-text {
              // width: 1.61rem;
              transform: scale(0.85);
            }
            .long-long-text {
              // width: 1.91rem;
              transform: scale(0.85);
            }
            .super-long-text {
              // width: 2.41rem;
              transform: scale(0.6);
            }
          }
          .courseright {
            opacity: 0;
            animation-direction: alternate !important;
            animation-fill-mode: forwards !important;
            display: flex;
            border-radius: 0.04rem 0.34rem 0.34rem 0.04rem;
            background: #e5f5ff;
            position: absolute;
            .content {
              width: 1.11rem;
              position: relative;
              min-height: 0.53rem;
              // padding: 0.05rem;
              word-break: break-all;
              display: flex;
              align-items: center;
              > p {
                position: absolute;
                width: 115%;
                top: 42%;
                left: -5%;
                transform: scale(0.83) translateY(-50%);
                font-size: 0.12rem;
                font-family: SourceHanSerifCN;
              }
            }
            .long-text {
              // width: 1.61rem;
              transform: scale(0.85);
            }
            .long-long-text {
              transform: scale(0.85);
              // width: 1.91rem;
            }
            .super-long-text {
              // width: 2.41rem;
              transform: scale(0.6);
            }
            .month {
              width: 0.53rem;
              height: 0.53rem;
              line-height: 0.53rem;
              text-align: center;
              background: #259ed6;
              border-radius: 50%;
              color: #fff;
              > span {
                font-size: 0.24rem;
              }
            }
          }
          .course1 {
            left: 20px;
            top: 390px;
            transform-origin: right top;
          }
          .course2 {
            right: 0;
            top: 394px;
            transform-origin: left bottom;
          }
          .course3 {
            left: 20px;
            top: 323px;
            transform-origin: right top;
          }
          .course4 {
            right: 0;
            top: 325px;
            transform-origin: left top;
          }
          .course5 {
            left: 20px;
            top: 209px;
            transform-origin: right bottom;
          }
          .course6 {
            right: 0px;
            top: 133px;
            transform-origin: left bottom;
          }
          .course7 {
            left: 20px;
            top: 135px;
            transform-origin: right top;
          }
          .course8 {
            right: 0;
            top: 51px;
            transform-origin: left bottom;
          }
          .course9 {
            left: 20px;
            top: 62px;
            transform-origin: right bottom;
          }
          .course10 {
            left: 2.51rem;
            bottom: 5.3rem;
            transform-origin: left top;
          }
          .longText-left {
            // left: -2.2rem;
          }
          .longlongText-left {
            // left: -0.65rem;
          }
          .superlongText-left {
            // left: -1.15rem;
          }
          .longleaf4 {
            // left: -2.05rem;
          }

          .doing1 {
            animation: transformOne 1s linear;
          }
          .doing2 {
            animation: transformOne 1s linear;
          }
          .doing3 {
            animation: transformOne 1s linear;
          }
          .doing4 {
            animation: transformTwo 1s linear;
          }
          .doing5 {
            animation: transformTwo 1s linear;
          }
          .doing6 {
            animation: transformOne 1s linear;
          }
          .doing7 {
            animation: transformThree 1s linear;
          }
          .doing8 {
            animation: transformFour 1s linear;
          }
          .doing9 {
            animation: transformTwo 1s linear;
          }
          .doing10 {
            animation: transformFour 1s linear;
          }
          .leave {
            animation: transformReverse 0.5s linear;
          }
          @keyframes transformOne {
            0% {
              opacity: 0;
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(5deg);
            }
            50% {
              transform: rotate(10deg);
            }
            75% {
              transform: rotate(5deg);
            }
            100% {
              opacity: 1;
              transform: rotate(0deg);
            }
          }
          @keyframes transformOneReverse {
            0% {
              opacity: 1;
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(5deg);
            }
            50% {
              transform: rotate(10deg);
            }
            75% {
              transform: rotate(5deg);
            }
            100% {
              opacity: 0;
              transform: rotate(0deg);
            }
          }
          @keyframes transformTwo {
            0% {
              opacity: 0;
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(-2deg);
            }
            50% {
              transform: rotate(-4deg);
            }
            75% {
              transform: rotate(-2deg);
            }
            100% {
              opacity: 1;
              transform: rotate(0deg);
            }
          }
          @keyframes transformTwoReverse {
            0% {
              opacity: 1;
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(-2deg);
            }
            50% {
              transform: rotate(-4deg);
            }
            75% {
              transform: rotate(-2deg);
            }
            100% {
              opacity: 0;
              transform: rotate(0deg);
            }
          }
          @keyframes transformThree {
            0% {
              opacity: 0;
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(2deg);
            }
            50% {
              transform: rotate(4deg);
            }
            75% {
              transform: rotate(2deg);
            }
            100% {
              opacity: 1;
              transform: rotate(0deg);
            }
          }
          @keyframes transformThreeReverse {
            0% {
              opacity: 1;
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(2deg);
            }
            50% {
              transform: rotate(4deg);
            }
            75% {
              transform: rotate(2deg);
            }
            100% {
              opacity: 0;
              transform: rotate(0deg);
            }
          }
          @keyframes transformFour {
            0% {
              opacity: 0;
              transform: rotate(0deg);
            }
            25% {
              transform: rotate(-5deg);
            }
            50% {
              transform: rotate(-10deg);
            }
            75% {
              transform: rotate(-5deg);
            }
            100% {
              opacity: 1;
              transform: rotate(0deg);
            }
          }
          @keyframes transformReverse {
            0% {
              opacity: 1;
              transform: translateY(0rem);
            }
            100% {
              opacity: 0;
              transform: translateY(0.3rem);
            }
          }
        }
      }
      &-6 {
        display: flex;
        flex-direction: column;
        .circle-panel-6-1 {
          position: absolute;
          z-index: -1;
          top: -24px;
          left: -98px;
          width: 278px;
          height: 278px;
          border-radius: 50%;
          background: linear-gradient(300deg, #fff 0%, #5ba6d1 100%);
        }
        & > h3 {
          flex-shrink: 0;
          margin-left: 27px;
          margin-bottom: 20px;
          font-size: 40px;
          font-weight: 600;
          line-height: 70px;
          color: #124089;
        }
        .people {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          &-message {
            // opacity: 0;
            transition: all 1s;
            &-2 {
              // flex: 1;
              // transition: none;
              // display: flex;
              // align-items: center;
              // max-height: 200px;
              display: flex;
              flex-direction: column;
              align-items: center;
              span {
                font-size: 14px;
                line-height: 25px;
                color: #124089;
                font-family: SourceHanSerifCN;
              }
            }
            > div {
              margin-bottom: 15px;
              color: #259ed6;
              font-family: SourceHanSerifCN;
              font-size: 24px;
              font-weight: normal;
              text-align: center;
            }
            .mr-top {
            }
            > p {
              width: 292px;
              margin: auto;
              font-size: 14px;
              line-height: 25px;
              color: #124089;
              font-family: SourceHanSerifCN;
            }
          }
          .carousel-leader {
            flex-shrink: 0;
            width: 113px;
            height: 244px;
            // margin: auto;
            margin-bottom: 15px;
            overflow: unset;
            .content-img {
              position: relative;
              width: 53px;
              height: 228px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
                object-fit: cover;
              }
              span {
                position: absolute;
                left: -3px;
                bottom: 4px;
                width: 56px;
                height: 39px;
                padding-left: 4px;
                color: #fff;
                font-size: 12px;
                line-height: 39px;
                border-radius: 8px;
                background: linear-gradient(90deg, #2082c1 0%, rgba(18, 64, 137, 0) 100%);
              }
            }
            .adm-swiper-item {
              opacity: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: opacity 0.5s;
              &.swiper-show {
                opacity: 1;
              }
              &.three {
                position: absolute;
                left: -52px;
              }
            }
            .adm-swiper-slide {
              transition: opacity 0.1s;
              &.adm-swiper-slide-active {
                .content-img {
                  flex-shrink: 0;
                  width: 145.79px;
                  height: 228px;
                  transform: scale(1.07);
                  span {
                    left: -7px;
                    bottom: 10px;
                    width: 109px;
                    height: 44px;
                    padding-left: 13px;
                    line-height: 44px;
                    font-size: 24px;
                  }
                }
              }
            }
            .adm-swiper-indicator {
              display: none;
            }
          }
        }
      }
      &-7 {
        .circle-panel-7-1 {
          position: absolute;
          z-index: -1;
          top: 21px;
          left: 24px;
          width: 54px;
          height: 54px;
          border-radius: 50%;
          background: linear-gradient(300deg, #1a66a9 0%, #76d9fb 100%);
        }
        & > h3 {
          margin-left: 27px;
          font-size: 40px;
          font-weight: normal;
          line-height: 70px;
          color: #7bddff;
        }
        .des1 {
          width: 145px;
          height: 23px;
          margin-left: 27px;
          padding-left: 15px;
          border-radius: 11.5px;
          opacity: 1;
          background: #ffffff;
          font-size: 12px;
          line-height: 23px;
          color: #259ed6;
        }
        .des2 {
          margin-left: 27px;
          font-size: 12px;
          color: #ffffff;
          i {
            font-size: 24px;
            color: #7bddff;
          }
        }
        .nansha {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin-left: -133px;
          margin-top: 116px;
          width: 40px;
          height: 53px;
        }
        .map-china {
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 0;
          transform: translate(-50%, -50%) scale(0.45);
          .tooltip {
            transform-origin: bottom center;
            transform: scale(2.5);
            margin-left: -0.31rem;
            margin-top: -0.4rem;
          }
        }
        & > img {
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translate(-50%, -100%);
          margin-top: -30px;
          width: 211px;
          height: 132px;
        }
      }
      &-8 {
        // background: #934ab2;
      }
    }
  }
}
