.map-china {
  width: 7.82rem;
  height: 6.45rem;
  background: url("https://oss.5rs.me/oss/uploadfe/png/a1c56d275ae6eacc6bc26a5175c717e9.png") top left/7.82rem 6.45rem
    no-repeat;
  position: relative;
  .province {
    width: 0.09rem;
    height: 0.09rem;
    background: #fff;
    box-shadow: 0 0 0.05rem rgba(255, 2555, 255, 1);
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    .tooltip {
      font-family: SourceHanSerifCN;
      font-size: 0.12rem;
      color: #124089;
      line-height: 0.27rem;
      margin-top: -0.31rem;
      transform: translateX(-0.33rem);
      width: 0.74rem;
      height: 0.31rem;
      text-align: center;
      background: url("https://oss.5rs.me/oss/uploadfe/png/15b009a714e043f92f83b3a5d73b1a76.png") no-repeat center;
    }
  }
  .province1 {
    top: 1.08rem;
    right: 0.75rem;
  }
  .province2 {
    top: 1.56rem;
    right: 0.91rem;
  }
  .province3 {
    top: 1.97rem;
    right: 1.12rem;
  }
  .province4 {
    top: 2.35rem;
    right: 1.99rem;
  }
  .province5 {
    top: 2.5rem;
    right: 1.86rem;
  }
  .province6 {
    top: 3rem;
    right: 1.8rem;
  }
  .province7 {
    top: 3.76rem;
    right: 1.45rem;
  }
  .province8 {
    top: 3.92rem;
    right: 1.06rem;
  }
  .province9 {
    top: 4.12rem;
    right: 1.31rem;
  }
  .province1 {
    top: 1.08rem;
    right: 0.75rem;
  }
  .province10 {
    top: 3.85rem;
    right: 1.73rem;
  }
  .province11 {
    top: 3.31rem;
    right: 2.26rem;
  }
  .province12 {
    top: 2.88rem;
    right: 2.56rem;
  }
  .province13 {
    top: 2.28rem;
    right: 2.65rem;
  }
  .province14 {
    top: 2.81rem;
    right: 3.4rem;
  }
  .province15 {
    top: 3.49rem;
    right: 3.05rem;
  }
  .province16 {
    top: 4.1rem;
    right: 2.15rem;
  }
  .province17 {
    top: 4.4rem;
    right: 1.8rem;
  }
  .province18 {
    top: 4.88rem;
    right: 1.37rem;
  }
  .province19 {
    top: 5.42rem;
    right: 2.25rem;
  }
  .province20 {
    top: 4.49rem;
    right: 2.47rem;
  }
  .province21 {
    top: 4.29rem;
    right: 3.31rem;
  }
  .province22 {
    top: 3.14rem;
    right: 3.76rem;
  }
  .province23 {
    top: 4.01rem;
    right: 3.78rem;
  }
  .province24 {
    top: 4.8rem;
    right: 3.34rem;
  }
  .province25 {
    top: 5.5rem;
    right: 3.12rem;
  }
  .province26 {
    top: 5.09rem;
    right: 4.03rem;
  }
  .province27 {
    top: 6.06rem;
    right: 2.74rem;
  }
  .province28 {
    top: 5.63rem;
    right: 2.06rem;
  }
  .province29 {
    top: 5.68rem;
    right: 2.24rem;
  }
  .province30 {
    top: 5rem;
    right: 0.91rem;
  }
  .province31 {
    top: 2.69rem;
    right: 2.21rem;
  }
  .province32 {
    top: 2.95rem;
    right: 4.03rem;
  }
  .province33 {
    top: 1.36rem;
    right: 5.75rem;
  }
  .province34 {
    top: 4.08rem;
    right: 5.79rem;
  }
}
