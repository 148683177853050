@import "@/styles/common";

@font-face {
  font-family: SourceHanSerifCN;
  src: url("https://cdn.5rs.me/cdn/font/%E6%80%9D%E6%BA%90%E9%9B%85%E9%BB%91/思源黑体 CN-Regular.otf");
}
@font-face {
  font-family: SourceHanSansCN-Medium;
  src: url("https://cdn.5rs.me/cdn/font/%E6%80%9D%E6%BA%90%E9%9B%85%E9%BB%91/思源黑体 CN-Medium.otf");
}
@font-face {
  font-family: SourceHanSansCN-Bold;
  src: url("https://cdn.5rs.me/cdn/font/%E6%80%9D%E6%BA%90%E9%9B%85%E9%BB%91/思源黑体 CN-Bold.otf");
}
.Page-Home {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #eee;
  .next-btn {
    position: fixed;
    left: 50%;
    z-index: 50;
    img {
      width: 100%;
      height: auto;
    }
    &.pilot {
      width: 0.17rem;
      height: 0.21rem;
      transform: translateX(-0.085rem);
      bottom: 0.35rem;
      animation: doing 1.5s infinite linear;
    }
    &.down {
      transform: scale(1.5);
      transition: all 0.2s linear 0.2s;
    }
  }
  .content-page-content {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    overflow: hidden;
  }

  .header-content {
    position: fixed;
    top: 0.4rem;
    left: 50%;
    transform: translateX(-50%);
    width: 10.8rem;
    z-index: 20;

    &.enterAnimation {
      .transition-right,
      .transition-left {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .transition-right {
      opacity: 0;
      transform: translateX(0.8rem);
      transition: all 0.8s;
    }

    .transition-left {
      transition: all 1s;
      opacity: 0;
      transform: translateX(-0.8rem);
    }

    .nav-logo {
      height: 0.52rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
        display: block;
        width: 1.36rem;
        height: 0.51rem;
        cursor: pointer;
        object-fit: cover;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .nav {
        display: flex;
        align-items: center;
        z-index: 10;
        font-size: 0.14rem;

        &-item {
          margin-right: 0.22rem;
          font-size: 0.18rem;
          cursor: pointer;
          color: #fff;
          font-family: SourceHanSerifCN;
          text-shadow: #666 0 0 4px;
        }

        .login {
          width: 0.33rem;
          height: 0.33rem;
          object-fit: cover;
          cursor: pointer;

          img {
            width: 0.33rem;
            height: 0.33rem;
          }
        }
      }
    }
    .count-text {
      position: absolute;
      top: 5.1rem;
      left: 6.36rem;
      z-index: 100;
      width: 0.6rem;
      height: 0.63rem;
      background: url(https://oss.5rs.me/oss/uploadfe/png/719473131fb6d0f64a98f41d3957035a.png) center center/100% 100%
        no-repeat;
    }
  }
  .home-footer {
    position: relative;
    .Modal {
      position: absolute;
      .real-modal {
        height: 100vh;
      }
    }
  }
}
.Page-Home-m {
  height: unset;
  .content-page-content {
    position: unset;
    width: 100%;
  }
  .header-content {
    position: absolute;
    // top: 0;
    // width: 80vw;
    // padding: 20px 9vw 20px 11vw;
    // box-sizing: content-box;
    // background-color: #12478f;
    // background: linear-gradient(to left, #2082c1, #124089);
  }
  .count-text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5.2rem;
    right: 4.8rem;
    left: unset;
    width: 1.3rem;
    height: 1.3rem;
    z-index: 19;
    border-radius: 50%;
    background: linear-gradient(to left, #2082c1, #124089);
    background-size: 100% 100%;
    .count-text {
      width: 0.8rem;
      height: 0.8rem;
      background: url(https://oss.5rs.me/oss/uploadfe/png/719473131fb6d0f64a98f41d3957035a.png) center center/100% 100%
        no-repeat;
    }
  }
}
@keyframes doing {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.1rem);
  }
  100% {
    transform: translateY(0.15rem);
  }
}
